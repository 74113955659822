import React from 'react';
import CompanyMenu from './header/companyMenu';
import GlobalMenu from './header/globalMenu';
function Header(props) {
    const {selectedCompany} = props
    return ( <div id="header">
        {
            selectedCompany !== "" 
                ? <CompanyMenu 
                    setSelectedCompany={props.setSelectedCompany}
                    selectedCompany={selectedCompany}  
                    filters={props.filters}
                    compareSector={props.compareSector} 
                    setCompareSector={props.setCompareSector}
                    companySector={props.companySector}
                    updateFilters={props.updateFilters}
                    sector={props.sector}>
                  </CompanyMenu> 
                : <GlobalMenu 
                    data={props.data}
                    summaryModel={props.summaryModel}
                    filters={props.filters}
                    companies={props.companies}>
                  </GlobalMenu>
        }
    </div> );
}

export default Header;