import React from 'react';
function ViewOwnMatrix(props) {
    // console.log(`./Matrices/${props.selectedCompany}_${props.filters["Year"][0]}.jpg`)
    return ( 
        <a id ="viewOwnMatrixLink" href={`./Matrices/${props.selectedCompany}_${props.filters["Year"][0]}.jpg`} target="blank" >
            <span id="viewOwnMatrix">
                view source matrix
               
            </span>
        </a>
    );
}

export default ViewOwnMatrix;