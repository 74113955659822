import React, {useRef} from 'react';
import { useEffect, useMemo } from 'react';
import shareIcon from '../../../../assets/icons/share.svg'
import shareDone from '../../../../assets/icons/shareDone.svg'
import copiedNotification from '../../../../assets/graphics/copiedNotification.svg'
import { useState } from 'react';

// const copy = ()=> {
//     let copyText = document.querySelector("#link-text");
//     copyText.select();
//     document.execCommand("copy");
//   }

function Share(props) {
    const shareButton = useRef(null)
    const [inClipboard,setInClipboard] = useState(false);
    const share = async ()=>{
        // shareButton.current.innerHTML = "link copied"

        shareButton.current.setAttribute("data-inclipboard",true)
        setInClipboard( true)
        const query = Object.entries(props.filters)
            .map(([name,valueList])=>name.toLowerCase().split('_')[0] + "=" + JSON.stringify(valueList))
            .reduce((acc,el)=> `${acc}&${el}`)
        const url = `${window.location.host}/?${query}${props.company && `&company=\"${props.company}\"`}`
        
        await navigator.clipboard.writeText(url)//.then(url=>url)
        await setTimeout(() => {
            shareButton.current.setAttribute("data-inclipboard",false)
            setInClipboard( false)
        }, 2000);
    }
    const update = ()=>{
        
        if(shareButton.current){
            // shareButton.current.innerHTML = "share view"
            
            shareButton.current.setAttribute("data-inclipboard",false)
            setInClipboard( false)
            
        }
        //document.getElementById('share').innerHTML = "share view"
        //document.getElementById('share').setAttribute("data-inclipboard",false)
    }
    useMemo(update,[props.filters])
    return ( <div id="share" onClick={share} ref={shareButton} style={{"backgroundImage": `url(${inClipboard?shareDone:shareIcon})` }}>
        <div id="copiedNotification" style={{"backgroundImage": `url(${copiedNotification})` }}></div>
    </div> );
}

export default Share;
// import React, {useRef} from 'react';
// import { useEffect } from 'react';

// function Share(props) {
//     const shareButton = useRef(null)
//     const share = ()=>{
//         shareButton.current.innerHTML = "link copied"
//         shareButton.current.setAttribute("data-inclipboard",true)
//         const query = Object.entries(props.filters)
//             .map(([name,valueList])=>name.toLowerCase().split('_')[0] + "=" + JSON.stringify(valueList))
//             .reduce((acc,el)=> `${acc}&${el}`)
//         const url = `${window.location.host}/?${query}${props.company && `&company=\"${props.company}\"`}`
//         navigator.clipboard.writeText(url)
//     }
//     useEffect(()=>{
//         shareButton.current.innerHTML = "share view"
//         shareButton.current.setAttribute("data-inclipboard",false)
//     })
//     return ( <div id="share" onClick={share} ref={shareButton}>
//         share view
        
//     </div> );
// }

// export default Share;