import { curveLinearClosed, filter } from 'd3';
import React, { useCallback, useEffect, useState } from 'react';
import Filters from '../filters';
import OptionsList from './filter/optionsList';
import arrowDown from '../../../../../assets/icons/dropdown.svg';
//ISSUE THERE IS A DOUBLE UPDATE TO FILTERKEYS – ONE COMING FROM HERE, ONE FROM THE OPT
function Filter(props) { 
    let [filterKeys,setFilterKeys] = useState(props.filters[props.col] === null ? [] :  props.filters[props.col]) // feed to callback from parent
    //let filterKeys = [] // feed to callback from parent
    let [isOpen,setIsOpen] = useState(false)
    let [editKeys,setEditKeys] = useState(props.editKeys)
    let [lock,setLock] = useState(false)
    let {clear} = props
    
    
    // let [lock,sl] = useState(true)
    // const handleOpenState = useCallback((newVal)=>{
    
    //     setIsOpen(newVal)
    // })
    // const handleOpenState = (newVal)=>{
    
    //     setIsOpen(newVal)
    // }
    // const doUpdate = ()=>{
    //     if (filterKeys.length === 0)return false
    //     if (props.filtersInDashboard === filterKeys) return false
    //     if (props.filtersInDashboard.length !== filterKeys.length) return true
    //     props.filtersInDashboard.every(e=>filterKeys.includes(e))
    //     return false
    // }
    
    // useEffect(()=>{
    //     const thisState = {"isOpen":isOpen,"setIsOpen":setIsOpen}
    //     const newState = Array.from(props.filterState)
    //     newState.push(thisState)
    
    //     props.setFilterState(newState)
    // })

    //remove from dashboard
    const isDefault = ()=>{
        
         if(props.filters[props.col]) {
            
            if(props.filters[props.col].length !== 0){
                
                return props.filters[props.col].every(e=>props.defaultValue.includes(e))
            }else{
                
                return props.defaultValue.length === filterKeys.length;
            }
         }else{
            
             return props.defaultValue.length === 0
         }
        
    }
    //WITH DEFAULT
    //this needs to change the clear all function too
    // useEffect(()=>{
    //     if( isDefault() && !lock || props.clear){
    //         props.setClear(false)
    //         setLock(isDefault)  
    //         //set to default
    //         setFilterKeys([...props.defaultValue])
    //     }
    // })
    
    //WITHOUT DEFAULT on share
    useEffect(()=>{
        if( props.name!== "year" && clear ){
                
                const value = props.filters[props.col] === null ? [] :  props.filters[props.col]
                setFilterKeys( value )
                props.setClear(false)
                
            }
        })
    if( props.name==="sector" && props.companySector!=="" ){
        // props.setClear(false)
        // setLock(true)  
        // setFilterKeys([props.companySector])
        filterKeys = [props.companySector]
    }
    const setFilters = ()=>{
        // if(props.name === "sector"){
            
        // }
        props.update( props.col, filterKeys )
    }
    const filterL = filterKeys.length;
    const generateName = ()=>{
        if( filterL === 0 ){
            return props.default
        }else if(  filterL === 1 ){ // why not working if starts ewith the netherlands ?
            if( filterKeys[0].length < 10) return filterKeys[0];
            return filterKeys[0].slice(0,9) + "..."
        }else{
            if( filterKeys[0].length < 10) return filterKeys[0] + " + " + ( filterL - 1 );
            return filterKeys[0].slice(0,7) + "... + " + ( filterL - 1 )
        }
    }
    const displayUI = ()=>{
        [...document.getElementsByClassName("optionsList")].forEach(e=>{
            e.setAttribute("data-open",e.id === props.name ? true : false)
        })
    }
    return (
        
        <div className="filterUI"  data-active={!isOpen&&filterL>0&&props.name!=="year"} debug={true/*||!isOpen&&props.name==="sector"&&props.companySector!==""}>*/}>
            <span className='filter-title'>{props.name}</span>
            <div className='select'  onClick={(e)=>{e.stopPropagation();setIsOpen(true); displayUI()}}>
                <span>{ generateName() } </span>
                <span className="arrow">&#8964;</span>
                {/* <div className="arrow" style={{backgroundImage:`url(${arrowDown})`}}></div> */}
                <OptionsList 
                    list={props.list}
                    type={props.type}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    name={props.name}
                    namePlural={props.namePlural}
                    setFilterKeys={setFilterKeys}
                    filterKeys={filterKeys}
                    setFilters={setFilters}
                    availableFilters={props.availableFilters}
                    companySector={props.companySector}
                    setCompanySector={props.setCompanySector}
                />
            </div>
        </div>

    );
}

export default Filter;