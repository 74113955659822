import React, { Component, useState } from 'react';
import * as d3 from 'd3'

//import table from '../data/data.csv'
import SideBar from './dashboard/sideBar';
import Chart from './dashboard/chart';
import Header from './dashboard/header'
import Info from './dashboard/info'
import Legend from './dashboard/legend'
import Share from './dashboard/share'
import Footer from './dashboard/footer';
import { useEffect } from 'react';
import { filter } from 'd3';


function Dashboard() {
    const table = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSQ3fNri_P8BU0nJ31YCdX17tt0N00JSDcA9SXA2sJ58vBctaw6yC12nXVuIdj0Iz4M4cUELxBggkeh/pub?output=csv"
    const [data,setData] = useState([]);
    const [summaryModel,setSummaryModel] = useState({});
    //const [mostRelevantyear,setMostRelevantyear] = useState("")
    const [filterDataStructure,setFilterDataStructure] = useState([])
    const [filters,setFilters] = useState({}) 
    const [companies,setCompanies] = useState([]) 
    const [selectedCompany,setSelectedCompany] = useState("")
    const [defaultCompany,setDefaultCompany] = useState("")
    const [defaultCompanySector,setDefaultCompanySector] = useState("")
    const [compareSector,setCompareSector] = useState(false)
    const [selectedIssue,setSelectedIssue] = useState({})
    const [companySector,setCompanySector] = useState("")
    // const [filterState,setFilterState] = useState([])
    // const [dataView,setDataView] = useState([])

    
    const updateFilters = (key,values)=>{
        //const thisFilter = filters[key] || []
        //values.forEach(v=> !thisFilter.includes(v) &&  thisFilter.push(v));
        
        filters[key] = values;
        
        setFilters({...filters})
    }
    const setUniqueCompanies = (data,filterDS) =>{
        const columns = filterDS.map(e=>e.col)
        const companiesUniques = data
            .map(e=>{
                
                const company = {name:e.Company}
                columns.forEach(c=>{
                    company[c] = e[c]
                })
                return company//{company:e["Company"],sector:e["Sector"],}
            })
            .filter((e,n,I)=>I.map(c=>c.name).indexOf(e.name) === n);
        setCompanies(companiesUniques);
        
    }
    useEffect(()=>{
        
        data.length === 0 && 
        //d3.dsv(";",table).then(data=>{
        d3.csv(table).then(data=>{
            const summary = {}
                summary["Country_of_reg"] = data.map(d=>d['Country_of_reg']).filter((e,n,I)=>I.indexOf(e)===n).length;
                summary["Companies"] = data.map(d=>d["Company"]).filter((e,n,I)=>I.indexOf(e)===n).length;
                summary["Sector"] = data.map(d=>d["Sector"]).filter((e,n,I)=>I.indexOf(e)===n).length;
            setData(data)
            setSummaryModel(summary)
            const lastYear = new Date().getFullYear() - 1
            const year = data
                .filter(row=>row.Year === lastYear.toString()).length > 0 
                ? lastYear.toString() 
                : ()=>{
                    const maxYear = d3.max(data,d=>+d.Year); 
                    return maxYear.toString()
                }
            //setMostRelevantyear(year)
            
            
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
                
            
            const defaultCountry = urlParams.get("country") ? JSON.parse(urlParams.get("country")) : [];
            const defaultYear = urlParams.get("year") ? JSON.parse(urlParams.get("year")) : [year];
            const defaultCompanyParam = urlParams.get("company") ? JSON.parse(urlParams.get("company")) : "";
            
            let defaultCompanySector = ""
            if(defaultCompanyParam!=="" ){
                for (let d of data){
                    if(d["Company"] === defaultCompanyParam){
                        defaultCompanySector = d["Sector"]
                        
                        break;
                    }
                }
            }
            const defaultSector = defaultCompanySector!==""? [defaultCompanySector] : urlParams.get("sector") ? JSON.parse(urlParams.get("sector")) : [];

            setDefaultCompany(defaultCompanyParam)
            setDefaultCompanySector(defaultCompanySector)
            setSelectedCompany(defaultCompanyParam)
            
            
            
            updateFilters("Sector",defaultSector)
            updateFilters("Country_of_reg",defaultCountry)
            updateFilters("Year",defaultYear)
            
            const filterDS = [
                {name:"sector", col:"Sector", default: "all sectors", namePlural:"sectors", defaultValue:defaultSector, type:{choice:"multiple", search:true}},
                {name:"country", col:"Country_of_reg", default: "all countries", namePlural:"countries", defaultValue:defaultCountry, type:{choice:"multiple", search:true}},
                {name:"year", col:"Year", default: "all years", namePlural:"years", defaultValue:defaultYear,type:{choice:"single", search:false}},
            ].map(e=>{
                const {col} = e;
                const uniques = data.map(e=>e[col])
                    .filter((el,n,I)=>I.indexOf(el)===n)
                e.list = uniques;
                

                return e;
            })
            setFilterDataStructure(filterDS);


            setUniqueCompanies(data,filterDS)

            // const companiesUniques = data
            //     .map(e=>e["Company"])
            //     .filter((e,n,I)=>I.indexOf(e) === n);
            // setCompanies(companiesUniques);
        })
    })
   const resetUI = ()=>{
    [...document.getElementsByClassName("optionsList")].forEach(e=>{
        e.setAttribute("data-open",false)
    })
    setSelectedIssue({})
   }
   const availableFilters = ()=>{
        /// IF COMPANY SELECTED FILTER OUT THE YEARS, SET SECTOR AND COUNTRY 
        const filteredDataByFilter = filterDataStructure.map(thisFilter=>{
            const selectedCompanySector = selectedCompany === "" || data.filter(d=> selectedCompany === d["Company"])[0]["Sector"]
            const multipleFilters = filterDataStructure
                //.filter(e=>e.type.choice !== "single")
                .filter(e=>e.col !== thisFilter.col)
                .map(e=>e.col)
                // 
            // const singles =  filterDataStructure
            //     .filter(e=>e.type.choice === "single")
            const filteredData = [...data]
                .filter( d => selectedCompany !== "" ? d.Company === selectedCompany : true )
                .filter( d => selectedCompanySector || d["Sector"] === selectedCompanySector ) // filter company
                .filter( d => multipleFilters.every( col => {
                    //
                    return filters[col].includes(d[col]) || filters[col].length === 0
                })) // filter cols
            return filteredData;
            })
            // const available = Object.entries(filters).map(([key,list])=> {
                
            //     return ({[key]:list.filter(f=>filteredData.map(e=>e[key]).includes(f))}) // fix this and return list of available filters
            // })
            // 
            const available = filterDataStructure.length>0&&filterDataStructure
                .map((ds,i)=>{
                    const {col,list} = ds
                    const avl = list.filter((filterVal)=>filteredDataByFilter[i].map(e=>e[col]).includes(filterVal))
                    return {[ds.col]:avl}
                }).reduce((acc,el)=> ({...acc, ...el}) ,{})
                
            //Object.entries().map(([key,list])=> ({[key]:list.filter(f=>filteredData.map(e=>e[key]).includes(f))}))
            
            return available // fix this and return list of available filters
            
    }
   
    return (
        <div id="dashboard" onClick={resetUI}>
            <SideBar filterDataStructure={filterDataStructure} 
                companies={companies} 
                filters={filters} 
                sector={filters["Sector"]} 
                companySector={companySector}
                update={updateFilters} 
                setSelectedCompany={setSelectedCompany} 
                defaultCompany={defaultCompany} 
                defaultCompanySector={defaultCompanySector}
                selectedCompany={selectedCompany} 
                setCompareSector={setCompareSector}
                availableFilters={availableFilters()}
                setCompanySector={setCompanySector}
                >
            </SideBar>
            <div id="main">
                <Header companies={companies} 
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    compareSector={compareSector} 
                    setCompareSector={setCompareSector} 
                    data={data} 
                    filters={filters} 
                    sector={companySector} 
                    updateFilters={updateFilters} 
                    summaryModel={summaryModel}>
                </Header>
            
                <Chart data={data} 
                    selectedCompany={selectedCompany}
                    filters={filters}
                    compareSector={compareSector}
                    setSelectedIssue={setSelectedIssue}>
                </Chart>
                <Footer filters={filters} company={selectedCompany} ></Footer>
            </div>
            {/* <Legend selectedIssue={selectedIssue} compareSector={compareSector}></Legend> */}
            <Info selectedIssue={selectedIssue}></Info>
            {/* <Share filters={filters} company={selectedCompany}></Share> */}
        </div>
    );
}

export default Dashboard;