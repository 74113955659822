import React from 'react';
import Filter from './filters/filter';
function Filters(props) {
    const generateFilterUI = ()=>{
        const UI = props.filterDataStructure.map((e,n)=><Filter 
            key={n} 
            name={e.name} 
            namePlural={e.namePlural} 
            companySector={props.companySector}
            setCompanySector={props.setCompanySector}
            col={e.col} 
            default={e.default} 
            list={e.list} 
            update={props.update} 
            type={e.type} 
            defaultValue={e.defaultValue} 
            editKeys={e.editing}
            filters={props.filters}
            filterState={props.filterState} 
            setFilterState={props.setFilterState}
            availableFilters={props.availableFilters[e.col]}
            clear={props.clear}
            setClear={props.setClear}
            />)//filtersInDashboard={props.filters[e.col] || []}
        return UI;
    }

    //const filtersL = Object.values(props.filterDataStructure).length>0 && Object.values(props.filterDataStructure).map(e=>e.list).reduce((acc,el)=>acc.concat(el)).length
    return (
        <div id="filters">
            <div id="filterList">
                {generateFilterUI()}
            </div>
        </div>
    );
}

export default Filters;