import React, { useRef } from 'react';
import * as d3 from 'd3'
import { useEffect } from 'react';
function Info(props) {
    const info = useRef(null)
    
    const {pageX,pageY} = props.selectedIssue.event || {pageX:0,pageY:0}
    const {name,list} = props.selectedIssue.data || {name:"",list:[]}
    const {definition} = props.selectedIssue.data || ""
    const companyLevel = Array.isArray(list)?
        list.map(le=>le["Issue name (from own matrix)"])
        .filter((e,n,I)=>I.indexOf(e) === n)
        .sort((a,b)=>a.length-b.length)
        :[list["Issue name (from own matrix)"]]
    const companies = Array.isArray(list)?
        list.map(le=>le["Company"])
        .filter((e,n,I)=>I.indexOf(e) === n)
        .sort((a,b)=>a.length-b.length)
        :[list["Company"]]
    useEffect(()=>{
        const {width,height} = info.current.getBoundingClientRect()
        const isTop = pageY < window.innerHeight/2;
        info.current.setAttribute("data-top",isTop)
        info.current.style.transform = `translate(${pageX - (width / 2)}px,${isTop ? pageY + companyLevel.length : pageY - height - companyLevel.length}px)`
        
    })
    return ( 
        <div id="info" ref={info} data-top={false} style={{display:name===""&&"none"}}>
            <div id="infoTailTop"></div>
            <div id="infoBody">
                <p id="infoTitle">{name} <br/>____</p>
                <p id="infoDefinition">{definition}</p>
                <div id="infoCaption">
                    IS AN ISSUE FOR
                </div>
                <div id="companyList">
                    {companies.map((cl,n)=><span key={n}>{cl}{companies.length>1&&","} &nbsp;</span>)}
                </div>
                <div id="infoCaption">
                    INCLUDED ISSUE{companyLevel.length>1&&"S"}
                </div>
                <div id="infoList">
                    {companyLevel.map((cl,n)=><span key={n} title={cl.length > 15 ? cl : '' }>{cl.length < 15? cl  : `${cl.slice(0,14)}...` }</span>)}
                </div>
            </div>
            <div id="infoTail"></div>
        </div> 
    );
}

export default Info;