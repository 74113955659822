import React from 'react';
import Legend from './footer/legend';
import Shares from './footer/shares';
function Footer(props) {
    return ( <div id="footer">
        <Legend company={props.company}></Legend>
        <Shares filters={props.filters} company={props.company}></Shares>
    </div> );
}

export default Footer;