import React  from 'react';
import Opt from './optionsList/opt';
import searchIcon from '../../../../../../assets/icons/search.svg'
function OptionsList(props) {
    let [displayedList,setDisplayedList] = React.useState(props.list)
    const filterDisplayedList = (pattern)=>{
        const regex = new RegExp(`^${pattern.toLowerCase()}`)
        const filteredList = props.list.filter(e=>e.toLowerCase().match(regex))
        setDisplayedList([...filteredList])
    }
    const confirmSelection = ()=>{
        props.setFilters()
        props.setIsOpen(false)
    }
    //if(props.name === "country")console.log("KEYS FROM ABOVE",props.filterKeys)
    const list = (list)=>{
        return list.map((e,n)=> <Opt  
            key={n} 
            n={n} 
            name={e} 
            //filterKeys={props.filterKeys} 
            //filterKeys={props.name == "sector" && props.companySector!==""?(!props.filterKeys.includes(props.companySector)&&props.filterKeys.push(props.companySector),props.filterKeys):props.companySector} 
            //filterKeys={props.name == "sector" && props.companySector!==""?(!props.filterKeys.includes(props.companySector)&&props.filterKeys.push(props.companySector),props.filterKeys):props.filterKeys} 
            filterKeys={props.name == "sector" && props.companySector!==""?[props.companySector]:props.filterKeys} 
            setFilterKeys={props.setFilterKeys}
            companySector={props.companySector} 
            setCompanySector={props.setCompanySector}
            display={displayedList.includes(e)?true:false}
            confirmSelection={confirmSelection}
            setIsOpen={props.setIsOpen}
            setFilters={props.setFilters}
            choice={props.type.choice}
            available={props.availableFilters?props.availableFilters.includes(e):false}
        />)
    }
    return (
        <div id={props.name} className="optionsList" data-open={props.isOpen} >
            {props.type.search && <div className="searchOpt">
                <label htmlFor="" className="lens">
                    <input type="text" className="searchBarOpt" onKeyUp={(e)=>filterDisplayedList(e.target.value)}></input>
                    <div className="lens-before" style={props.type.choice==="multiple"?{backgroundImage: `url(${searchIcon})`}:{backgroudColor:"--var(red)"}}></div>
                </label>
            </div>}
            <div className="listBody">
                {list(props.list)}
            </div>
            { <div className="selectedOpt" onClick={(e)=>{e.stopPropagation(); confirmSelection()}}>
                <div data-none={props.filterKeys.length>0?false:true} className="selectedCount">
                {props.type.choice==="single"? 
                    `select ${props.filterKeys[0]}`
                    :props.filterKeys.length>0
                        ?`select ${props.filterKeys.length} ${props.filterKeys.length<=1?props.name:props.namePlural}` 
                        :`view all ${props.namePlural}`
                    }
                </div>
            </div>}
        </div>
    );
}

export default OptionsList;