import React, { useState } from 'react';
import Company from './byLetter/company';
function ByLetter(props) {
    //const [childBottom,setChildBottom] = useState(0);
    
    //let selected = props.list?.includes(props.selectedCompany) ? true : false;
    const generateList = ()=>{
        
        const sublist = props.list.map((e,n)=><Company 
            setCompanySector={props.setCompanySector}
            setSelectedCompany={props.setSelectedCompany} 
            //setChildBottom={setChildBottom} 
            selectedCompany={props.selectedCompany} 
            selectCompanyInChart={props.selectCompanyInChart} 
            key={n} 
            name={e.name} 
            sector={e.Sector} 
            display={Array.isArray(e.name.toLowerCase().match(props.pattern.toLowerCase()))}
            />);
        return sublist;
    }
    //const getStats = function(){}
    return ( 
        <div className='by-letter' 
            id={props.id} 
            data-letter={props.letter}
            /*style={selected?{position:'sticky',top:-document.getElementById(props.id).getBoundingClientRect().height - childBottom }:{}}*/
            >
            {generateList()}
        </div> 
    );
}
 
export default ByLetter;

