import React from 'react';
function Legend(props) {
    return ( <div id="legendFooter">
<svg version="1.1" x="0px" y="0px" 
	 viewBox="0 0 440 37.9" >
<defs>
</defs>
<g className="legendBottomSt0">
	<g>
		<g>
			<path className="legendBottomSt1" d="M59.8,8.3h0.6l3.3,5.6l0.9,1.6h0c0-0.8-0.1-1.5-0.1-2.3V8.3h0.5v7.9h-0.6l-3.3-5.6L60.3,9h0
				c0,0.8,0.1,1.5,0.1,2.2v4.9h-0.5V8.3z"/>
			<path className="legendBottomSt1" d="M67.4,13.3c0-1.9,1.2-3,2.6-3s2.6,1.1,2.6,3c0,1.9-1.2,3-2.6,3S67.4,15.2,67.4,13.3z M72,13.3
				c0-1.5-0.9-2.6-2-2.6s-2,1.1-2,2.6c0,1.5,0.9,2.5,2,2.5S72,14.8,72,13.3z"/>
			<path className="legendBottomSt1" d="M74.5,15.8c0-0.4,0.2-0.6,0.5-0.6s0.5,0.2,0.5,0.6c0,0.3-0.2,0.5-0.5,0.5S74.5,16.1,74.5,15.8z"/>
			<path className="legendBottomSt1" d="M80.3,13.3c0-1.9,1.2-3,2.6-3s2.6,1.1,2.6,3c0,1.9-1.2,3-2.6,3S80.3,15.2,80.3,13.3z M84.9,13.3
				c0-1.5-0.9-2.6-2-2.6s-2,1.1-2,2.6c0,1.5,0.9,2.5,2,2.5S84.9,14.8,84.9,13.3z"/>
			<path className="legendBottomSt1" d="M86.9,10.5l0.8-0.1h1.8v0.5h-2.7V10.5z M87.7,9.1c0-1.1,0.5-1.7,1.4-1.7c0.3,0,0.6,0.1,0.8,0.2L89.8,8
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.6,0-0.9,0.5-0.9,1.3v7h-0.5V9.1z"/>
			<path className="legendBottomSt1" d="M93.7,12.2c0-2.5,1.4-4.1,3.4-4.1c0.9,0,1.6,0.5,2.1,0.9l-0.3,0.4c-0.4-0.5-1-0.8-1.7-0.8
				c-1.7,0-2.8,1.4-2.8,3.6c0,2.2,1.1,3.6,2.8,3.6c0.8,0,1.4-0.3,2-1l0.3,0.3c-0.6,0.7-1.3,1.1-2.4,1.1
				C95.1,16.3,93.7,14.7,93.7,12.2z"/>
			<path className="legendBottomSt1" d="M100.9,13.3c0-1.9,1.2-3,2.6-3s2.6,1.1,2.6,3c0,1.9-1.2,3-2.6,3S100.9,15.2,100.9,13.3z M105.5,13.3
				c0-1.5-0.9-2.6-2-2.6s-2,1.1-2,2.6c0,1.5,0.9,2.5,2,2.5S105.5,14.8,105.5,13.3z"/>
			<path className="legendBottomSt1" d="M108.3,10.4h0.4l0.1,0.9h0c0.5-0.6,1.2-1,1.8-1c0.9,0,1.4,0.5,1.6,1.2c0.7-0.7,1.3-1.2,2-1.2
				c1.1,0,1.7,0.7,1.7,2.2v3.7h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8c-0.5,0-1.1,0.4-1.7,1.1v4.3h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8
				c-0.5,0-1.1,0.4-1.7,1.1v4.3h-0.5V10.4z"/>
			<path className="legendBottomSt1" d="M118.5,10.4h0.4l0.1,0.7h0c0.6-0.5,1.2-0.9,2-0.9c1.5,0,2.3,1.2,2.3,2.9c0,2-1.2,3.1-2.5,3.1
				c-0.5,0-1.1-0.3-1.7-0.7l0,1.1v2.1h-0.5V10.4z M122.8,13.2c0-1.4-0.5-2.5-1.8-2.5c-0.6,0-1.2,0.3-1.9,1v3.4
				c0.7,0.5,1.3,0.7,1.7,0.7C121.9,15.9,122.8,14.8,122.8,13.2z"/>
			<path className="legendBottomSt1" d="M125.1,14.8c0-1.3,1.2-1.9,3.7-2.2c0-0.9-0.2-1.8-1.4-1.8c-0.8,0-1.4,0.4-1.8,0.7l-0.2-0.4
				c0.4-0.3,1.2-0.7,2.1-0.7c1.4,0,1.8,1,1.8,2.2v3.7h-0.4l-0.1-0.7h0c-0.6,0.5-1.3,0.9-2,0.9C125.9,16.3,125.1,15.8,125.1,14.8z
				 M128.8,14.9v-2c-2.4,0.3-3.2,0.8-3.2,1.7c0,0.8,0.6,1.1,1.2,1.1C127.6,15.9,128.1,15.5,128.8,14.9z"/>
			<path className="legendBottomSt1" d="M131.9,10.4h0.4l0.1,0.9h0c0.6-0.6,1.2-1,2-1c1.2,0,1.7,0.7,1.7,2.2v3.7h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8
				c-0.7,0-1.2,0.4-1.9,1.1v4.3h-0.5V10.4z"/>
			<path className="legendBottomSt1" d="M138.6,8.6c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5C138.8,9,138.6,8.8,138.6,8.6
				z M138.8,10.4h0.5v5.8h-0.5V10.4z"/>
			<path className="legendBottomSt1" d="M141.5,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C142.7,16.3,141.5,15.2,141.5,13.3z M145.7,12.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H145.7z"/>
			<path className="legendBottomSt1" d="M147.6,15.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C148.8,16.3,148.1,16,147.6,15.5z"/>
			<path className="legendBottomSt1" d="M59.5,20.6c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5C59.8,21,59.5,20.8,59.5,20.6
				z M59.8,22.4h0.5v5.8h-0.5V22.4z"/>
			<path className="legendBottomSt1" d="M62.5,25.3c0-1.9,1.2-3,2.6-3c0.7,0,1.2,0.3,1.7,0.7l0-1.1v-2.4h0.5v8.6h-0.4l-0.1-0.7h0
				c-0.5,0.5-1.1,0.9-1.9,0.9C63.4,28.3,62.5,27.3,62.5,25.3z M66.8,26.9v-3.4c-0.6-0.5-1.1-0.7-1.7-0.7c-1.1,0-2,1.1-2,2.6
				c0,1.5,0.7,2.5,1.9,2.5C65.6,27.9,66.2,27.5,66.8,26.9z"/>
			<path className="legendBottomSt1" d="M69.5,25.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4H70c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C70.7,28.3,69.5,27.2,69.5,25.3z M73.7,24.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H73.7z"/>
			<path className="legendBottomSt1" d="M76.3,22.4h0.4l0.1,0.9h0c0.6-0.6,1.2-1,2-1c1.2,0,1.7,0.7,1.7,2.2v3.7H80v-3.6c0-1.2-0.4-1.8-1.3-1.8
				c-0.7,0-1.2,0.4-1.9,1.1v4.3h-0.5V22.4z"/>
			<path className="legendBottomSt1" d="M83.3,26.6v-3.7h-0.9v-0.4l0.9-0.1l0.1-1.7h0.5v1.7h1.7v0.5h-1.7v3.7c0,0.7,0.2,1.2,1,1.2
				c0.2,0,0.5-0.1,0.7-0.2l0.1,0.4c-0.3,0.1-0.7,0.2-0.9,0.2C83.6,28.3,83.3,27.6,83.3,26.6z"/>
			<path className="legendBottomSt1" d="M87.1,20.6c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5C87.4,21,87.1,20.8,87.1,20.6
				z M87.4,22.4h0.5v5.8h-0.5V22.4z"/>
			<path className="legendBottomSt1" d="M89.9,22.5l0.8-0.1h1.8v0.5h-2.7V22.5z M90.7,21.1c0-1.1,0.5-1.7,1.4-1.7c0.3,0,0.6,0.1,0.8,0.2L92.8,20
				c-0.2-0.1-0.5-0.2-0.7-0.2c-0.6,0-0.9,0.5-0.9,1.3v7h-0.5V21.1z"/>
			<path className="legendBottomSt1" d="M93.4,30.7l0.1-0.5c0.1,0,0.3,0.1,0.5,0.1c0.7,0,1.2-0.6,1.5-1.5l0.2-0.5l-2.4-5.9h0.6l1.4,3.6
				c0.2,0.5,0.4,1.1,0.6,1.6h0c0.2-0.5,0.4-1.1,0.5-1.6l1.2-3.6h0.5l-2.3,6.5c-0.3,1-0.9,1.9-1.9,1.9C93.8,30.8,93.5,30.8,93.4,30.7
				z"/>
			<path className="legendBottomSt1" d="M102.4,26.8c0-1.3,1.2-1.9,3.7-2.2c0-0.9-0.2-1.8-1.4-1.8c-0.8,0-1.4,0.4-1.8,0.7l-0.2-0.4
				c0.4-0.3,1.2-0.7,2.1-0.7c1.4,0,1.8,1,1.8,2.2v3.7h-0.4l-0.1-0.7h0c-0.6,0.5-1.3,0.9-2,0.9C103.2,28.3,102.4,27.8,102.4,26.8z
				 M106.1,26.9v-2c-2.4,0.3-3.2,0.8-3.2,1.7c0,0.8,0.6,1.1,1.2,1.1C104.8,27.9,105.4,27.5,106.1,26.9z"/>
			<path className="legendBottomSt1" d="M108.5,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C109.7,28.3,109,28,108.5,27.5z"/>
			<path className="legendBottomSt1" d="M117.5,20.3h0.6v7.9h-0.6V20.3z"/>
			<path className="legendBottomSt1" d="M120,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C121.3,28.3,120.6,28,120,27.5z"/>
			<path className="legendBottomSt1" d="M125.4,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C126.6,28.3,125.9,28,125.4,27.5z"/>
			<path className="legendBottomSt1" d="M131.3,26.1v-3.7h0.5v3.6c0,1.2,0.4,1.8,1.3,1.8c0.7,0,1.2-0.4,1.9-1.2v-4.3h0.5v5.8h-0.4l-0.1-1h0
				c-0.6,0.7-1.2,1.1-2,1.1C131.9,28.3,131.3,27.6,131.3,26.1z"/>
			<path className="legendBottomSt1" d="M137.8,25.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C139,28.3,137.8,27.2,137.8,25.3z M142,24.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H142z"/>
		</g>
	</g>
	<path className="legendBottomSt2" d="M50.6,18.9c0,9.6-7.7,17.3-17.3,17.3c-9.6,0-17.3-7.7-17.3-17.3c0-9.6,7.7-17.3,17.3-17.3
		C42.9,1.6,50.6,9.4,50.6,18.9z M33.4,13.2c-6.4,0-11.5,5.2-11.5,11.5c0,6.4,5.2,11.5,11.5,11.5c6.4,0,11.5-5.2,11.5-11.5
		C44.9,18.3,39.7,13.2,33.4,13.2z M33.4,20.9c-4.2,0-7.7,3.4-7.7,7.7s3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7S37.6,20.9,33.4,20.9z"/>
</g>
<g style={{"opacity": props.company === "" ? 0 : 1, "transition": props.company === "" ? '' : ".3s ease-out" }}>
	<g>
		<g>
			<g>
				<line className="legendBottomSt3" x1="268.7" y1="18.9" x2="269.2" y2="18.9"/>
				<line className="legendBottomSt4" x1="271.2" y1="18.9" x2="330" y2="18.9"/>
				<line className="legendBottomSt3" x1="331" y1="18.9" x2="331.5" y2="18.9"/>
			</g>
		</g>
		<g>
			<circle className="legendBottomSt5" cx="331.5" cy="18.9" r="2.2"/>
			
				<ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 236.9239 337.2341)" className="legendBottomSt6" cx="331.5" cy="18.9" rx="8.7" ry="8.7"/>
		</g>
		<circle className="legendBottomSt1" cx="268.7" cy="18.9" r="2.2"/>
		
			<ellipse transform="matrix(0.2298 -0.9732 0.9732 0.2298 188.5383 276.096)" className="legendBottomSt7" cx="268.7" cy="18.9" rx="18.4" ry="18.4"/>
	</g>
	<g>
		<g>
			<g>
				<path className="legendBottomSt1" d="M182.5,15.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
					c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5
					c-0.9,0-1.3,0.5-1.3,1c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C183.7,16.3,183,16,182.5,15.5z"/>
				<path className="legendBottomSt1" d="M188,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
					c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C189.2,16.3,188,15.2,188,13.3z M192.3,12.9c0-1.5-0.7-2.2-1.7-2.2
					c-1,0-1.9,0.8-2,2.2H192.3z"/>
				<path className="legendBottomSt1" d="M194.3,13.3c0-1.9,1.2-3,2.6-3c0.8,0,1.3,0.3,1.7,0.7l-0.3,0.4c-0.4-0.3-0.8-0.6-1.4-0.6
					c-1.2,0-2.1,1.1-2.1,2.6c0,1.5,0.8,2.5,2.1,2.5c0.6,0,1.1-0.3,1.5-0.7l0.3,0.4c-0.5,0.4-1.1,0.8-1.8,0.8
					C195.4,16.3,194.3,15.2,194.3,13.3z"/>
				<path className="legendBottomSt1" d="M200.8,14.6v-3.7h-0.9v-0.4l0.9-0.1l0.1-1.7h0.5v1.7h1.7v0.5h-1.7v3.7c0,0.7,0.2,1.2,1,1.2
					c0.2,0,0.5-0.1,0.7-0.2l0.1,0.4c-0.3,0.1-0.7,0.2-0.9,0.2C201.1,16.3,200.8,15.6,200.8,14.6z"/>
				<path className="legendBottomSt1" d="M204.4,13.3c0-1.9,1.2-3,2.6-3s2.6,1.1,2.6,3c0,1.9-1.2,3-2.6,3S204.4,15.2,204.4,13.3z M209,13.3
					c0-1.5-0.9-2.6-2-2.6s-2,1.1-2,2.6c0,1.5,0.9,2.5,2,2.5S209,14.8,209,13.3z"/>
				<path className="legendBottomSt1" d="M211.8,10.4h0.4l0.1,1.1h0c0.4-0.7,1-1.2,1.6-1.2c0.2,0,0.4,0,0.6,0.1l-0.1,0.5c-0.2-0.1-0.3-0.1-0.5-0.1
					c-0.5,0-1.1,0.4-1.6,1.5v3.9h-0.5V10.4z"/>
				<path className="legendBottomSt1" d="M219,15.4V7.6h0.5v7.9c0,0.3,0.1,0.4,0.2,0.4c0.1,0,0.1,0,0.2,0l0.1,0.4c-0.1,0-0.2,0.1-0.4,0.1
					C219.2,16.3,219,16,219,15.4z"/>
				<path className="legendBottomSt1" d="M221.8,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
					c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C223,16.3,221.8,15.2,221.8,13.3z M226,12.9c0-1.5-0.7-2.2-1.7-2.2
					c-1,0-1.9,0.8-2,2.2H226z"/>
				<path className="legendBottomSt1" d="M227.6,10.4h0.6l1.3,3.6c0.2,0.6,0.4,1.1,0.6,1.7h0c0.2-0.5,0.4-1.1,0.6-1.7l1.3-3.6h0.5l-2.1,5.8h-0.6
					L227.6,10.4z"/>
				<path className="legendBottomSt1" d="M233.8,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
					c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C235,16.3,233.8,15.2,233.8,13.3z M238,12.9c0-1.5-0.7-2.2-1.7-2.2
					c-1,0-1.9,0.8-2,2.2H238z"/>
				<path className="legendBottomSt1" d="M240.5,15.4V7.6h0.5v7.9c0,0.3,0.1,0.4,0.2,0.4c0,0,0.1,0,0.2,0l0.1,0.4c-0.1,0-0.2,0.1-0.4,0.1
					C240.8,16.3,240.5,16,240.5,15.4z"/>
				<path className="legendBottomSt1" d="M216.6,20.6c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5
					C216.9,21,216.6,20.8,216.6,20.6z M216.8,22.4h0.5v5.8h-0.5V22.4z"/>
				<path className="legendBottomSt1" d="M219.4,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
					c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5
					c-0.9,0-1.3,0.5-1.3,1c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C220.6,28.3,219.9,28,219.4,27.5z"
					/>
				<path className="legendBottomSt1" d="M224.7,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
					c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5
					c-0.9,0-1.3,0.5-1.3,1c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C225.9,28.3,225.2,28,224.7,27.5z"
					/>
				<path className="legendBottomSt1" d="M230.7,26.1v-3.7h0.5v3.6c0,1.2,0.4,1.8,1.3,1.8c0.7,0,1.2-0.4,1.9-1.2v-4.2h0.5v5.8h-0.4l-0.1-1h0
					c-0.6,0.7-1.2,1.1-2,1.1C231.2,28.3,230.7,27.6,230.7,26.1z"/>
				<path className="legendBottomSt1" d="M237.1,25.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
					c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C238.3,28.3,237.1,27.2,237.1,25.3z M241.3,24.9c0-1.5-0.7-2.2-1.7-2.2
					c-1,0-1.9,0.8-2,2.2H241.3z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path className="legendBottomSt8" d="M348.9,13.3c0-1.9,1.2-3,2.6-3c0.8,0,1.3,0.3,1.7,0.7l-0.3,0.4c-0.4-0.3-0.8-0.6-1.4-0.6
				c-1.2,0-2.1,1.1-2.1,2.6c0,1.5,0.8,2.5,2.1,2.5c0.6,0,1.1-0.3,1.5-0.7l0.3,0.4c-0.5,0.4-1.1,0.8-1.8,0.8
				C350,16.3,348.9,15.2,348.9,13.3z"/>
			<path className="legendBottomSt8" d="M354.7,13.3c0-1.9,1.2-3,2.6-3s2.6,1.1,2.6,3c0,1.9-1.2,3-2.6,3S354.7,15.2,354.7,13.3z M359.3,13.3
				c0-1.5-0.9-2.6-2-2.6s-2,1.1-2,2.6c0,1.5,0.9,2.5,2,2.5S359.3,14.8,359.3,13.3z"/>
			<path className="legendBottomSt8" d="M362.1,10.4h0.4l0.1,0.9h0c0.5-0.6,1.2-1,1.8-1c0.9,0,1.4,0.5,1.6,1.2c0.7-0.7,1.3-1.2,2-1.2
				c1.1,0,1.7,0.7,1.7,2.2v3.7h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8c-0.5,0-1.1,0.4-1.7,1.1v4.3h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8
				c-0.5,0-1.1,0.4-1.7,1.1v4.3h-0.5V10.4z"/>
			<path className="legendBottomSt8" d="M372.3,10.4h0.4l0.1,0.7h0c0.6-0.5,1.2-0.9,2-0.9c1.5,0,2.3,1.2,2.3,2.9c0,2-1.2,3.1-2.5,3.1
				c-0.5,0-1.1-0.3-1.7-0.7l0,1.1v2.1h-0.5V10.4z M376.6,13.2c0-1.4-0.5-2.5-1.8-2.5c-0.6,0-1.2,0.3-1.9,1v3.4
				c0.7,0.5,1.3,0.7,1.7,0.7C375.7,15.9,376.6,14.8,376.6,13.2z"/>
			<path className="legendBottomSt8" d="M378.9,14.8c0-1.3,1.2-1.9,3.7-2.2c0-0.9-0.2-1.8-1.4-1.8c-0.8,0-1.4,0.4-1.8,0.7l-0.2-0.4
				c0.4-0.3,1.2-0.7,2.1-0.7c1.4,0,1.8,1,1.8,2.2v3.7h-0.4l-0.1-0.7h0c-0.6,0.5-1.3,0.9-2,0.9C379.7,16.3,378.9,15.8,378.9,14.8z
				 M382.6,14.9v-2c-2.4,0.3-3.2,0.8-3.2,1.7c0,0.8,0.6,1.1,1.2,1.1C381.4,15.9,381.9,15.5,382.6,14.9z"/>
			<path className="legendBottomSt8" d="M385.7,10.4h0.4l0.1,0.9h0c0.6-0.6,1.2-1,2-1c1.2,0,1.7,0.7,1.7,2.2v3.7h-0.5v-3.6c0-1.2-0.4-1.8-1.3-1.8
				c-0.7,0-1.2,0.4-1.9,1.1v4.3h-0.5V10.4z"/>
			<path className="legendBottomSt8" d="M391.8,18.7l0.1-0.5c0.1,0,0.3,0.1,0.5,0.1c0.7,0,1.2-0.7,1.5-1.5l0.2-0.5l-2.4-5.9h0.6l1.4,3.6
				c0.2,0.5,0.4,1.1,0.6,1.6h0c0.2-0.5,0.4-1.1,0.5-1.6l1.2-3.6h0.5l-2.3,6.5c-0.3,1-0.9,1.9-1.9,1.9
				C392.1,18.8,391.9,18.8,391.8,18.7z"/>
			<path className="legendBottomSt8" d="M401.2,15.4V7.6h0.5v7.9c0,0.3,0.1,0.4,0.2,0.4c0,0,0.1,0,0.2,0l0.1,0.4c-0.1,0-0.2,0.1-0.4,0.1
				C401.4,16.3,401.2,16,401.2,15.4z"/>
			<path className="legendBottomSt8" d="M404,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C405.2,16.3,404,15.2,404,13.3z M408.2,12.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H408.2z"/>
			<path className="legendBottomSt8" d="M409.8,10.4h0.6l1.3,3.6c0.2,0.6,0.4,1.1,0.6,1.7h0c0.2-0.5,0.4-1.1,0.6-1.7l1.3-3.6h0.5l-2.1,5.8H412
				L409.8,10.4z"/>
			<path className="legendBottomSt8" d="M416,13.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C417.2,16.3,416,15.2,416,13.3z M420.2,12.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H420.2z"/>
			<path className="legendBottomSt8" d="M422.7,15.4V7.6h0.5v7.9c0,0.3,0.1,0.4,0.2,0.4c0,0,0.1,0,0.2,0l0.1,0.4c-0.1,0-0.2,0.1-0.4,0.1
				C423,16.3,422.7,16,422.7,15.4z"/>
			<path className="legendBottomSt8" d="M349.1,20.6c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5S349.1,20.8,349.1,20.6z
				 M349.3,22.4h0.5v5.8h-0.5V22.4z"/>
			<path className="legendBottomSt8" d="M351.9,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C353.1,28.3,352.4,28,351.9,27.5z"/>
			<path className="legendBottomSt8" d="M357.2,27.5l0.3-0.4c0.5,0.4,1,0.7,1.8,0.7c0.9,0,1.4-0.5,1.4-1.1c0-0.7-0.7-1-1.4-1.3
				c-0.9-0.3-1.8-0.7-1.8-1.6c0-0.8,0.7-1.5,1.9-1.5c0.6,0,1.2,0.3,1.6,0.6l-0.3,0.4c-0.4-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.3,0.5-1.3,1
				c0,0.7,0.7,0.9,1.4,1.2c0.9,0.3,1.9,0.6,1.9,1.8c0,0.9-0.7,1.6-1.9,1.6C358.5,28.3,357.7,28,357.2,27.5z"/>
			<path className="legendBottomSt8" d="M363.2,26.1v-3.7h0.5v3.6c0,1.2,0.4,1.8,1.3,1.8c0.7,0,1.2-0.4,1.9-1.2v-4.2h0.5v5.8h-0.4l-0.1-1h0
				c-0.6,0.7-1.2,1.1-2,1.1C363.7,28.3,363.2,27.6,363.2,26.1z"/>
			<path className="legendBottomSt8" d="M369.6,25.3c0-1.9,1.2-3,2.5-3c1.4,0,2.2,1,2.2,2.7c0,0.1,0,0.3,0,0.4h-4.1c0,1.5,0.9,2.5,2.2,2.5
				c0.6,0,1.1-0.2,1.5-0.5l0.2,0.4c-0.5,0.3-1,0.6-1.8,0.6C370.8,28.3,369.6,27.2,369.6,25.3z M373.8,24.9c0-1.5-0.7-2.2-1.7-2.2
				c-1,0-1.9,0.8-2,2.2H373.8z"/>
		</g>
	</g>
    <line className="legendBottomSt9" x1="439.7" y1="1.6" x2="439.7" y2="36.2"/>
    <line className="legendBottomSt9" x1="167.2" y1="1.6" x2="167.2" y2="36.2"/>
    <line className="legendBottomSt9" x1="0.2" y1="1.6" x2="0.2" y2="36.2"/>
</g>

</svg>

    </div> );
}

export default Legend;