import { precisionPrefix } from 'd3';
import React from 'react';
import DownloadChart from './exports/downloadChart';
import Share from './exports/share';
function Exports(props) {
    return ( <div id="exports">
        <DownloadChart></DownloadChart>
        <Share filters={props.filters} company={props.company}></Share>
    </div> );
}

export default Exports;