import React from 'react';
import Summary from './globalMenu/summary';
function GlobalMenu(props) {
    const dict = {Companies:"Companies",Country_of_reg:"Countries",Sector:"Sectors"}
    const {summaryModel} = props
    const {filters} = props
    const columns = Object.entries(props.filters).filter(([key,value])=>value.length>0).map(([key,value])=>key)
    const companiesNumber = props.companies.filter( d => columns.every( col => {
        return props.filters[col].includes(d[col])
        }) 
    ).length
    
    const summary = { 
    ... {Companies:companiesNumber} ,
    ... (Object.entries(filters).length>0 ? Object.entries(filters).map(([key,value])=>{ 
        if(Object.keys(summaryModel).includes(key)){
            return {[key]:value.length===0?props.data.map(d=>d[key]).filter((e,n,I)=>I.indexOf(e)===n).length:value.length} 
        }
    })
    .filter(e=>e!==undefined)
    .reduce((acc,el)=>{return {...acc, ...el}})
    :{})}
    
    
    //const summary = {}
    //summary.country = props.data.map(d=>d['Country_of_reg']).filter((e,n,I)=>I.indexOf(e)===n).length;
    
    //summary.sector = props.data.map(d=>d["Sector"]).filter((e,n,I)=>I.indexOf(e)===n).length;
    
    const generateSummary = ()=>{
        return Object.entries(summaryModel).map(([key,value],n)=><Summary key={n} name={dict[key]} valueModel={value} actualValue={summary[key]}></Summary>)
    }
    return ( 
        <div id="globalMenu">
            <div id="summarySide">
                {generateSummary()}
            </div>
        </div> 
    );
}

export default GlobalMenu;