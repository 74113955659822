import React from 'react';
import DownloadChart from './shares/downloadChart';
import Share from './shares/share';
function Shares(props) {
    return ( <div id="sharesFooter">
        <Share filters={props.filters} company={props.company} ></Share>
        <DownloadChart></DownloadChart>
    </div> );
}

export default Shares;