import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';


function Chart(props) {
    
    const [setupState,setSetupState] = useState(false)
    //const [compareMode,setCompareMode] = useState(false)
    const {setSelectedIssue}  = props
    const dataImage = props.data
    let scaleX;
    let scaleY;
    useEffect(()=>{
        let data = [...dataImage];
        // Object.entries(props.filters).forEach(entry => { 
        //     const [column,filter] = entry 
            
        //     data = [...data].filter( d=>d[column] === filter )
            
        //  })
        const columns = Object.entries(props.filters).filter(([key,value])=>value.length>0).map(([key,value])=>key)
        
        //const columns = Object.keys(props.filters)
        
        const selectedCompanySector = props.selectedCompany !== "" && data.filter(d=>props.selectedCompany === d["Company"])[0]["Sector"]
        //setCompareMode(selectedCompanySector?true:false) 
        const sectorComparison = props.selectedCompany !== "" && props.compareSector;
        if(sectorComparison){
            data = [...data]
                .filter( d => d["Sector"] === selectedCompanySector ) // filter company
                .filter( d => columns.every( col => props.filters[col].includes(d[col])) ) // filter cols
                // .filter( d => d["Year"] === filters["year"] )
        }else{
            data = [...data]
                .filter( d =>  props.selectedCompany === "" || props.selectedCompany === d["Company"])// filter company
                .filter( d => columns.every( col => props.filters[col].includes(d[col])) ) // filter cols
            
        }
        if(data.length > 0){
            typeof d3.select("#chartSvg").nodes()[0] === 'undefined' && drawSvg()
            const svg = d3.select("#chartSvg");
            const canvas = setupState ? svg.select("#canvas") : svg.append("g").attr("id","canvas");
            if(!setupState){
                canvas.append("g").attr("id","axisLayer")
                canvas.append("g").attr("id","issuesLayer")
                canvas.append("g").attr("id","linesLayer")
                canvas.append("g").attr("id","labelsLayer")
            }
            setSetupState(true)
            let issues = []
            if(sectorComparison){
                

                // filer by company
                // group by company issues
                //    save list of sector issues

                // filter by sector
                //   group by sector issue
                
                // populate lines dataset by matching company issues with sector issues ~ if  thisCompany.sector_issue.includes(d.id) push x and y of both
                
                // issues = d3.groups(data,d=>d["Company"] === props.selectedCompany, d=>d["Company"] === props.selectedCompany?d["Issue name (from own matrix)"]:d["Sector Issue"])
                // .map(g=>{
                //     const output = g[1].map(e=>{
                //         return {
                //             id : e[0],
                //             name : g[0]?e[0]:`${e[0]} sector`, 
                //             x : d3.mean(e[1], d=>parseFloat(d["X Axis (Company)"])),
                //             y : d3.mean(e[1], d=>parseFloat(d["Y Axis (Stakeholder)"])),
                //             value : !g[0] ? e[1].length + 1 : 1 ,
                //             list : e[1],
                //             context : !g[0],
                //             sectorName : !g[0],
                //         }
                //     })
                //     return output
                // })
                // .flat()//d3.groups(data,d=>d["CombinedUnique Value"])
                const sectorLevel = d3.groups(data, d=>d["Sector Issue"])
                .map(e=>{
                    return {
                        id : e[0],
                        name : `${e[0]} sector`, 
                        x : d3.mean(e[1], d=>parseFloat(d["X Axis (Company)"])), 
                        y : d3.mean(e[1], d=>parseFloat(d["Y Axis (Stakeholder)"])),
                        value : e[1].length,
                        list : e[1],
                        context : true,
                    }
                })

                const companyLevel = data.filter(row=>row["Company"] === props.selectedCompany)
                    .map(e=>{
                        
                    return {
                        id : e["Sector Issue"],
                        name : e["Issue name (from own matrix)"],
                        x : parseFloat(e["X Axis (Company)"]),//d3.mean(e[1], d=>parseFloat(d["X Axis (Company)"])), 
                        y : parseFloat(e["Y Axis (Stakeholder)"]),//d3.mean(e[1], d=>parseFloat(d["Y Axis (Stakeholder)"])),
                        value : 1,
                        list : e,
                        context : false,
                    }
                })
                issues = [...sectorLevel,...companyLevel]

            }else{
             issues = d3.groups(data, d=>d[ props.selectedCompany !== "" ? "Issue name (from own matrix)" : "Global Issue for chart" ])
                .map(e=>{
                    return {
                        id : e[0], 
                        name : e[0], 
                        x : d3.mean(e[1], d=>parseFloat(d["X Axis (Company)"])), 
                        y : d3.mean(e[1], d=>parseFloat(d["Y Axis (Stakeholder)"])),
                        value : e[1].length,
                        list : e[1],
                        context : false,
                    }
                })
            }
            //const issues = d3.rollup(data, v=>v.length, d=>d["Issue name (from own matrix)"])
            const w = svg.node().getBoundingClientRect().width;
            const extentX = d3.extent(dataImage, d=>parseFloat(d["X Axis (Company)"]))
            const scaleXfunc = d3.scaleLinear().domain(extentX).range([10,w-10])
            scaleX = scaleXfunc
            const h = svg.node().getBoundingClientRect().height;
            const extentY = d3.extent(dataImage, d=>parseFloat(d["Y Axis (Stakeholder)"]))
            const scaleYfunc = d3.scaleLinear().domain(extentY).range([h-130,10])
            scaleY = scaleYfunc
            
            
        drawChart(issues,canvas)
    }else if(setupState){
        const canvas = d3.select("#chartSvg").select("#canvas")
        drawChart([],canvas)
    }
    d3.select("#filters").select("p").on("click",drawChart([],d3.select("chartSvg").select("#canvas")))
    })
    const drawSvg = ()=>{
        d3.select("#chart")
            .append("svg")
            .attr("id","chartSvg")
            .attr("viewBox","0 0 700 700")
            //.attr("preserveAspectRatio","xMidYMid meet")
    }
    const chart = {
        label:{
            marginTop: 2.5,
            paddingSide: 5,
            text:{marginTop: 15,}
        },
        value:{
            abundance:1,
        },
        transition:1500,
        ccr:2,
    }
    const drawChart = (data,canvas)=>{
        data = data.sort((a,b)=>d3.descending(a.value,b.value))
        const noValueDifference = data.every(e=>e.value === data[0].value) 
        const uniterestingMax = d3.max(data,d=>d.value)  === d3.mode(data,d=>d.value)
    
        const meaningfulDatapoints = {
            minX: d3.min(data,d=>d.x),
            maxX: d3.max(data,d=>d.x),
            minY: d3.min(data,d=>d.y),
            maxY: d3.max(data,d=>d.y),
            minValue: d3.min(data,d=>d.value),
            maxValue: d3.max(data,d=>d.value),
        }
        
        const isMeaningful = (dp)=>{
            return (
                meaningfulDatapoints.minX === dp.x 
                || meaningfulDatapoints.minY === dp.y 
                || meaningfulDatapoints.maxX === dp.x 
                || meaningfulDatapoints.maxY === dp.y 
                || (meaningfulDatapoints.maxValue === dp.value && !noValueDifference && !uniterestingMax) 
                || ( props.selectedCompany !== "" && !props.compareSector)
                ? true : false
                );
        }
        const nameCleaningPattern = / |&|\//g;
        const issuesGeometry = canvas.select("#issuesLayer")
            .selectAll(".issue")
            .data(data,(d,i)=>d.name)
            .join(
                enter=>{
                const circles =  enter.append("g")
                    .classed("issue",true)
                    .attr("data-x",d=>d.x)
                    .attr("data-name",d=>d.name)
                    .attr("transform",d=>`translate(${scaleX(d.x)},${scaleY(d.y)})`)
                circles
                    .append("circle")
                    .classed("circleValue",true)
                    .classed("context",d=> d.context)
                    .on("mouseover", function(e,d){
                        d3.selectAll(".labels").style("opacity",d=>isMeaningful(d)?0.25:0)
                        d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}`).style("opacity",1).raise()
                        d3.selectAll(`.labels`).each(function(label){
                            if(/*label.id.includes(d.id)*/ label.id.replace(" sector","") === d.id){
                                d3.select(this).style("opacity",1).raise()
                            }
                        })
                        d3.selectAll(`.comparisonLine`).each(function(l){
                            if(l.idSource === d.id || l.idTarget === d.id ){
                                d3.select(this).style("opacity",1)
                            }else{
                                d3.select(this).transition().style("opacity",0.25)
                            }
                        })
                        
                        if(props.compareSector) {
                            d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}_sector`).style("opacity",1).raise()
                            d3.select(`#label_${d.name.replace(nameCleaningPattern,'_').replace('_sector','')}`).style("opacity",1).raise()
                        }
                    })
                    .on("mouseout", function(e,d){ 
                        d3.selectAll(".labels").style("opacity",d=>isMeaningful(d)?1:0)
                        d3.selectAll(`.comparisonLine`).transition().style("opacity",1)
                        //d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}`).style("opacity",d=>isMeaningful(d)?1:0).lower()
                    })
                    .on("click", (e,d)=>{
                        
                        e.stopPropagation()
                        setSelectedIssue({event:e,data:d})
                    })
                    .transition()
                    .delay(chart.transition)
                    .duration(chart.transition/3)
                    .attr("r", d=>d.value+chart.ccr + chart.value.abundance)
                circles
                    .append("circle")
                    .classed("circleCenter",true)
                    .classed("context",d=> d.context)
                    .transition()
                    //.delay(chart.transition)
                    .duration(chart.transition/3)
                    .attr("r", chart.ccr)
                
                   
                

                    return enter
                },
                update=>{
                    
                    const circles =  update
                    .attr("data-x",d=>d.x)
                    .attr("data-name",d=>d.name)
                        
                    circles
                        .select(".circleValue")
                        .on("mouseover", function(e,d){
                            d3.selectAll(".labels").style("opacity",d=>isMeaningful(d)?0.25:0)
                            d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}`).style("opacity",1).raise()
                            d3.selectAll(`.labels`).each(function(label){
                                
                                if(/*label.id.includes(d.id)*/ label.id.replace(" sector","") === d.id){
                                    d3.select(this).style("opacity",1).raise()
                                }
                            })
                            d3.selectAll(`.comparisonLine`).each(function(l){
                                if(l.idSource === d.id || l.idTarget === d.id ){
                                    d3.select(this).style("opacity",1);
                                }else{
                                    d3.select(this).transition().style("opacity",0.25);
                                }
                            })
                            
                            props.compareSector && d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}_sector`).style("opacity",1).raise()
                        })
                        .on("mouseout", function(e,d){ 
                            d3.selectAll(".labels").style("opacity",d=>isMeaningful(d)?1:0)
                            d3.selectAll(`.comparisonLine`).transition().style("opacity",1)
                        })
                        .on("click", (e,d)=>{
                            
                            e.stopPropagation()
                            setSelectedIssue({event:e,data:d})
                        })
                        .transition()
                        .duration(chart.transition)
                        .attr("r", d=>d.value+chart.ccr + chart.value.abundance)
                   
                    circles
                        .transition()
                        .duration(chart.transition)
                        .attr("transform",d=>`translate(${scaleX(d.x)},${scaleY(d.y)})`)
                    
                },
                exit=>{
                    exit.select(".circleValue").transition().attr("r",0)
                    exit.select(".circleCenter").transition().attr("r",0)
                    setTimeout(() => {
                        exit.remove()
                        //exit.selectAll(".labels").remove()
                    }, chart.transition);
                    return exit
                }
            )
            issuesGeometry && issuesGeometry.each(function(){d3.select(this.raise())})
        canvas.select("#labelsLayer")
            .selectAll(".labels")
            .data(data,(d,i)=>d.name)
            .join(
                enter=>{
                const labelStyle = chart.label;
                
                const labels = enter.append("g")
                    .attr("transform",d=>`translate(${scaleX(d.x)},${scaleY(d.y)})`)
                    .classed("labels",true)
                    .attr("id",d=>`label_${d.name.replace(nameCleaningPattern,'_')}`)
                    .style("opacity",0)
                const labelBg = labels
                    .append("rect")
                    .classed("labelBg",true)
                    .attr("rx","5px")

                const labelText = labels.append("text")
                    .classed("labelText",true)
                    .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.text.marginTop  + labelStyle.marginTop)
                    .text(d=>d.context?d.name.slice(0,-6):d.name)
                    //.attr("opacity",d=>d.value+chart.ccr + chart.value.abundance>15?1:0)
                const labelsList = []
                labelText.each(d=>labelsList.push(d.name))
                const labelWidths = labelText.nodes().map(e=>e.getBoundingClientRect().width)
                
                const labelWidthDict = labelsList.length > 0 ? Object.assign(...labelsList.map((e,n)=>({[e]:labelWidths[n]}))) : {}
                
                labelBg
                    .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.marginTop)
                    .attr("x",(d)=>-labelWidthDict[d.name]/2 -labelStyle.paddingSide)
                    .attr("width",(d)=>labelWidthDict[d.name] + (labelStyle.paddingSide*2))
                    .attr("height",20)
                    //.attr("opacity",d=>d.value+chart.ccr + chart.value.abundance>15?1:0)
                labels
                .transition()
                .duration(chart.transition) 
                .style("opacity",d=>isMeaningful(d)?1:0)
                

                    return enter
                },
                update=>{
                    const labelStyle = chart.label;
                    const labels = update
                    const labelText = labels.select("text")
                        .classed("labelText",true)
                        .text(d=>d.context?d.name.slice(0,-6):d.name)
                        .transition()
                        .duration(chart.transition) 
                        .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.text.marginTop  + labelStyle.marginTop)

                    
                    const labelsList = []
                    labelText.each(d=>labelsList.push(d.name))
                    const labelWidths = labelText.nodes().map(e=>e.getBoundingClientRect().width)
                    
                    const labelWidthDict = labelsList.length > 0 ? Object.assign(...labelsList.map((e,n)=>({[e]:labelWidths[n]}))) : {}
                        const labelBg = labels
                            .select("rect")
                    labelBg
                        .transition()
                        .duration(chart.transition) 
                        .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.marginTop)
                        .attr("x",(d)=>-labelWidthDict[d.name]/2 -labelStyle.paddingSide)
        
                    labels
                        .transition()
                        .duration(chart.transition) 
                        .attr("transform",d=>`translate(${scaleX(d.x)},${scaleY(d.y)})`)
                        .style("opacity",d=>isMeaningful(d)?1:0)
                    // const labelStyle = chart.label;
                    
                    // d3.selectAll(".issue").each((d)=>{
                        
                    //     const labels =  d3.select(`#label_${d.name.replace(nameCleaningPattern,'_')}`)
                    //         .attr("transform",d=>`translate(${scaleX(d.x)},${scaleY(d.y)})`)
                        
                    //     const labelBg = labels.select(".labelBg")
                    //     const labelText = labels.select(".labelText")
                    //         .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.text.marginTop  + labelStyle.marginTop)
                    //         .text(d=>d.name)
                    //         //.attr("opacity",d=>d.value+chart.ccr + chart.value.abundance>15?1:0)
                            
                    //     const labelWidth = labelText.nodes().map(e=>e.getBoundingClientRect().width)
        
                    //     labelBg
                    //         .attr("y",d=>d.value+chart.ccr + chart.value.abundance + labelStyle.marginTop)
                    //         .attr("x",(d,i)=>-labelWidth[i]/2 -labelStyle.paddingSide)
                    //         .attr("width",(d,i)=>labelWidth[i] + (labelStyle.paddingSide*2))
                    //         .attr("height",20)
                    //         //.attr("opacity",d=>d.value+chart.ccr + chart.value.abundance>15?1:0)
                    //     })
                    //return update
                },
                exit=>{
                    exit.transition().style("opacity",d=>0)
                    setTimeout(() => {
                        exit.remove()
                    }, chart.transition);
                    return exit
                }
                )
        const lines = canvas.select("#linesLayer")
        if(props.compareSector){
            
            
            //setup data
            // const idDict = {}
            // const uniquesId =data.map(e=>e.id)
            // .filter((e,n,I)=>I.indexOf(e) === n)
            
            // uniquesId.forEach(e => {
            //     idDict[e] = {}
            // });

            //extract 
            const linesCoordinates = d3.groups(data, d=>d.id)
            .filter(e=>e[1].length>1)
            .map(e=>{
                const groups = e[1]
                // groups.filter(g=>g.context).length>1 && console.log("!!! MORE THAN ONE ROOT")
                const rootNode = groups.filter(g=>g.context)[0]
                const branches = groups.filter(g=>!g.context)
                
                // const couples = e[1]
                
                const tree = []
                for(let i in branches){
                    const line = {
                        idSource : rootNode.id,
                        idTarget : branches[i].id,
                        x1 : rootNode.x,
                        y1 : rootNode.y,
                        x2 : branches[i].x,
                        y2 : branches[i].y,
                    }
                    tree.push(line)
                }
                return tree
            }).flat();
            //save data source and target for hover
            const sameData = JSON.stringify(lines.selectAll(".comparisonLine").data()) === JSON.stringify(linesCoordinates)
            !sameData && lines.selectAll(".comparisonLine")
                .data(linesCoordinates,d=>d.id)
                .join("line")
                //.attr("id",d=>d.id) 
                .classed("comparisonLine",true)
                .attr("stroke-width",1.5)
                .attr("stroke-dasharray","2 1")
                .attr("stroke","#F4F9F9")
                .attr("x1",d=>scaleX(d.x1))
                .attr("y1",d=>scaleY(d.y1))
                .attr("x2",d=>scaleX(d.x1))
                .attr("y2",d=>scaleY(d.y1))
                .transition()
                .duration(700)
                .delay(chart.transition/3*2.5)
                .attr("x2",d=>scaleX(d.x2))
                .attr("y2",d=>scaleY(d.y2))
            
            
            
        }else{
            lines.selectAll(".comparisonLine").remove()
            
        }
        const axisLayer = d3.select("#axisLayer")
        //axis left
        if(axisLayer.selectAll("*").nodes().length===0){
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("stroke-dasharray","2 3")
                .attr("x1",10)
                .attr("y1",0)
                .attr("x2",10)
                .attr("y2",600)
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("x1",10)
                .attr("y1",0)
                .attr("x2",3)
                .attr("y2",12)
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("x1",10)
                .attr("y1",0)
                .attr("x2",17)
                .attr("y2",12)
            const labelLeft =  axisLayer.append("text")
                .attr("fill","#263472")
                .attr("id","labelY")
                .attr("transform","translate(0,10)")
                labelLeft.append("tspan").text("IMPACT ON")
                    .attr("font-weight","200")
                    .attr("x",25)
                    .attr("dy",10)
                labelLeft.append("tspan").text("STAKEHOLDERS")
                    .attr("font-weight","600")
                    .attr("x",25) 
                    .attr("dy","1.2em")
            //axis right
            const XaxisWidth = 690
            const XaxisStart = 10
            const YaxisStart = 10
            const YaxisHeight = 590
            // const arrow = {
            //     side1:{x2:12},
            //     side2:{x2:12},
            // }
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("stroke-dasharray","2 3")
                .attr("y2",YaxisStart+YaxisHeight)
                .attr("x2",XaxisStart)
                .attr("y1",YaxisStart+YaxisHeight)
                .attr("x1",XaxisStart+XaxisWidth)
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("y1",YaxisStart+YaxisHeight)
                .attr("x1",XaxisStart+XaxisWidth)
                .attr("y2",593)
                .attr("x2",688)
            axisLayer.append("line")
                .attr("stroke-width",1.5)
                .attr("stroke","#263472")
                .attr("y1",YaxisStart+YaxisHeight)
                .attr("x1",XaxisStart+XaxisWidth)
                .attr("y2",607)
                .attr("x2",688)
                const labelRightBG = axisLayer.append("rect")
                const labelRight =  axisLayer.append("text")
                .attr("fill","#263472")
                labelRight.append("tspan").text("IMPACT ON")
                    .attr("font-weight","200")
                labelRight.append("tspan").text("COMPANY")
                    .attr("font-weight","600")
                    .attr("dx","5")
                labelRight.attr("transform",function(){
                    const length = XaxisStart + XaxisWidth;
                    const height = YaxisStart + YaxisHeight;
                    const labelWidth = this.getBoundingClientRect().width
                    const labelHeight = this.getBoundingClientRect().height
                    const x = (length/2) - (labelWidth/2)
                    const y = height + (labelHeight/3)
                    const translate = `translate(${x},${y})`
                    labelRightBG
                        .attr("transform",translate)
                        .attr("y",-labelHeight)
                        .attr("x",-10)
                        .attr("width",labelWidth+10)
                        .attr("height",labelHeight + 5)
                        .attr("fill","#D1E0EA")
                    return translate
                })
            }
            console.log('here')
    }
    
    return (
        <div id="chart">
        </div>
    );
}

export default Chart;