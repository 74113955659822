import React, { useState } from 'react';
import backgroundImage from "../../../../../../assets/icons/close.svg"
function Company(props) {
    const selectThis = function(){
        props.setCompanySector(props.sector)
        props.setSelectedCompany(props.name)
        props.selectCompanyInChart(props.name)
        // const thisHeight = document.getElementById(`company-${props.name}`).getBoundingClientRect().height
        // props.setChildBottom(thisHeight)
        
    }
    const resetAll = (e)=>{
        e.stopPropagation();
        props.selectCompanyInChart("")
        props.setSelectedCompany("")
        props.setCompanySector("")
    }
    return ( 
    <div className="company" 
        // id={`company-${props.name}`} 
        onClick={selectThis}
        data-selected={props.name===props.selectedCompany?true:false}
        style={{display:props.display?"":"none"}}
        >
        {props.name}
        <div className="resetAll" 
            style={{"backgroundImage":`url(${backgroundImage})`}}
            onClick={(e)=>resetAll(e)}
        ></div>
    </div> );
}

export default Company;

