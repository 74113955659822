import React, { useRef } from 'react';
import * as d3 from 'd3'
import { useEffect } from 'react';
function Summary(props) {
    const {name} = props
    const {valueModel} = props
    const {actualValue} = props
    const thisSummaryIndex = useRef(null)

    useEffect(()=>{
        const index = d3.select(thisSummaryIndex.current)
            .select(".summaryIndex")
        let value = parseInt(index.text())
        
        const countUp = setInterval(() => {
            
                if(value > actualValue){
                    value--
                    index.text(value)
                }else if( value < actualValue){
                    value++
                    index.text(value)
                }else{
                    clearInterval(countUp)
                    index.text(value)
                }
                    
            
        }, 50);
    })
    return ( <div className="summary" ref={thisSummaryIndex}>
        <span className='summaryIndex'>
            0
        </span>
        <span className='summaryName'>{name}</span>
        <div className='ratio'>
            <div className="indexRatio" style={{width:`${(actualValue*100)/valueModel}%`}}></div>
            {/* {name +" "+ valueModel +" " +actualValue} */}

        </div>
    </div> );
}

export default Summary;