import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ByLetter from './companies/byLetter';
import SearchCompany from './companies/searchCompany';
function Companies(props) {
    const [currentLetter,setCurrentLetter] = useState("A");
    const [hoveringLetter,setHoveringLetter] = useState("");
    const [selectedCompany,setSelectedCompany] = useState("")
    const [pattern,setPattern] = useState("")
    //const list = props.list.filter(e=>props.sector.length!==0?props.sector.includes(e.sector):true);
    //const list = props.list.filter(e=>props.filters?Object.values(props.filters).some(m=>m.includes(e.sector)):true);
    
    const columns = Object.entries(props.filters).filter(([key,value])=>value.length>0).map(([key,value])=>key)
    let list = props.list.filter( d => columns.every( col => {
            return props.filters[col].includes(d[col])
        }) 
    ) 
    
    // const initials = list.map(e=>e.company[0])
    //     .filter((e,n,I)=>I.indexOf(e)===n)
    //     .sort((a,b)=>a.localeCompare(b))
    
    const initials = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    const slots = initials.map(()=>[]),
    zip = d3.zip(initials,slots),
    groupsMap = new Map(zip),
    groups = Object.fromEntries(groupsMap);
    list.forEach(e => {
        const initial = e.name[0];
        groups[initial].push(e);
    });

    const generateList = ()=>{
        const companies = Object.entries(groups).map(([key,value],n)=>{
         return <ByLetter 
            setCompanySector={props.setCompanySector}
            selectCompanyInChart={props.setSelectedCompany} 
            key={n} 
            list={value} 
            letter={key} 
            id={`letter-${key}`} 
            selectedCompany={props.selectedCompany} 
            
            setSelectedCompany={setSelectedCompany}
            pattern={pattern}/>
        })
        return companies
    }
    const scrollTo = (letter)=>{
        const companiesList = document.getElementById("companiesList");
        const target = document.getElementById(`letter-${letter}`).offsetTop//.getBoundingClientRect().y;
        companiesList.scrollTop = target;
        setTimeout(() => {
            setCurrentLetter(letter)
        }, 600);
    }
    const firstInView = ()=>{
        const byLetter = [...document.getElementsByClassName("by-letter")];
        const containerY = document.getElementById("companiesList").getBoundingClientRect().y
        const firstInView = byLetter.filter((e)=>e.getBoundingClientRect().bottom - containerY > 0)[0].getAttribute("data-letter")
        //GET THE SMALLEST... SOMEHOW
        currentLetter !== firstInView && setCurrentLetter(firstInView)
        
        
    }
    return (
        <div id="companies">
            {/** SEARCH **/}
            <SearchCompany setPattern={setPattern}></SearchCompany>
            <div id="companiesListWidget" onScroll={firstInView}>
                <div id="initialsList">
                    {/** GENERATE INITIALS AS LIST **/}
                    {initials.map((e,n)=><div onClick={()=>scrollTo(e)} 
                                              onMouseOver={()=>setHoveringLetter(e)} 
                                              onMouseOut={()=>setHoveringLetter("")} 
                                              data-selected={selectedCompany[0]===e} 
                                              data-active={groups[e].length!==0} 
                                              style={{fontWeight:currentLetter===e||hoveringLetter===e?800:200}} 
                                              className="initial" 
                                              key={n}>
                                            {e}
                                            </div>)}
                </div>
                <div id="companiesList" onScroll={firstInView}>
                    {generateList()}
                </div>
            </div>
        </div>
    );
}

export default Companies;