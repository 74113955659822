
import React, { useEffect, useState } from 'react';
import checked from '../../../../../../../assets/icons/checked.svg'
import radio from '../../../../../../../assets/icons/radio.svg'
function Opt(props) {
    
    //const [isChecked,setIsChecked] = useState(false)//TODO SET THEM ALL FALSE
    
    let isChecked = props.filterKeys.includes(props.name)
    // props.name === "UK" && console.log(isChecked,props.filterKeys,props.filterKeys.includes(props.name),props.name)
    // let noCompanySector =  props.companySector === ""
    const checkFilter = (e)=>{
        e.stopPropagation()
        if(props.choice === "multiple"){
            let {filterKeys} = props
            if(!isChecked ){
                
                filterKeys.push(props.name) 
            }else{
                
                if(props.name === props.companySector){
                    props.setCompanySector("")
                }
                filterKeys = filterKeys.filter(e=>e!==props.name) 
            }
            
            
            props.setFilterKeys([...filterKeys])
            
            //setIsChecked(!isChecked);
 
            
        }else if(props.choice === "single"){
            props.setFilterKeys([props.name])
         
        }
        
    }
    
    //if(!noCompanySector){
        //!isChecked && props.filterKeys.includes(props.name) && setIsChecked(true)
        //isChecked && !props.filterKeys.includes(props.name)  && setIsChecked(false)
    // }else{
    //     isChecked && setIsChecked(false)
    // }
    const selectedGraphic = props.choice === "multiple" ? checked : radio;
    return (
        <div className={`opt ${!props.display ? 'optHide' : ''} ${!props.available&&"unavailable"}` } data-checked={isChecked?'true':'false'} onClick={(e)=>checkFilter(e)} style={{"transition":  ` .${props.n*3}s ease-out .1s`}}>
            <div className="radio" style={{backgroundImage:isChecked?`url(${selectedGraphic})`:'', borderRadius: props.choice !== "multiple"&&"14px" }}></div>
            <span>{props.name}</span>
        </div>
    );
}

export default Opt;