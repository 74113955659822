import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import Dashboard from './components/dashboard';

function App() {
  //404
  if(window.location.href.match(/ *\/Matrices*/) && !window.location.href.match(/ *\/unavailable*/))window.location.href = "./unavailable.jpg"
  return (
    <div className="App">
      <Dashboard></Dashboard>
    </div>
  );
}

export default App;
