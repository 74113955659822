import e from 'cors';
import React from 'react';
import ViewOwnMatrix from './companyMenu/viewOwnMatrix';
function CompanyMenu(props) {

    const {selectedCompany} = props
    const {setCompareSector} = props
    const {compareSector} = props

    const controlComparisonMode = (e)=>{
        e.stopPropagation()
        const newState = !compareSector;
        setCompareSector(newState)
    }
    return ( 
        <>
            <div id="companyMenu">
                
                    <span id="companyInChart">{selectedCompany}</span>
            </div>
            
            <div id="companyButtons">
            <div id="goToSector"> <span id="goToSector-label">go to sector</span> <span id="goToSector-sector"  onClick={()=>{props.updateFilters("Sector",[props.sector]); props.updateFilters("country",[]); props.setSelectedCompany("")}}>{props.sector}</span></div>
                <div id="compare">
                    <span className='compare-label'>compare with sector</span>
                    <label className="switch">
                    <input type="checkbox"/>
                    <span className="slider round" onClick={(e)=>controlComparisonMode(e)}></span>
                    </label>
                </div>
                <ViewOwnMatrix filters={props.filters} selectedCompany={selectedCompany}></ViewOwnMatrix>
            </div> 
        </>
    );
}

export default CompanyMenu;