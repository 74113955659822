import React from 'react';
import View from './sideBar/view'
import logo from '../../assets/logo/logo.svg'
import about from '../../assets/icons/about.svg'
import closeIcon from '../../assets/icons/closeSection.svg'
import * as d3 from 'd3'
import { useEffect } from 'react';
import { useState, useMemo } from 'react';
// import { useRef } from 'react';
function SideBar(props) {
    const [introText,setIntroText] = useState("")
    const [aboutText,setAboutText] = useState([])
    const toggleAboutSection = ()=>{
      const aboutPage = document.getElementById("aboutPage");
      const aboutPageState = aboutPage.getAttribute("data-active")
      aboutPage.setAttribute("data-active", aboutPageState === "true" ? false : true)
    }
    useMemo(()=>{
        
        d3.csv("https://docs.google.com/spreadsheets/d/e/2PACX-1vTcLjHA1EHWrx8GW3JyRSFTf25yjh5m9N9zL9R4rUyO8TzPjpFaZvz4UfFWcB_uBBFSeC9VURIQpVzT/pub?output=csv")
            .then(text=>{
                const intro = text.filter(par=>par.SECTION === "intro")[0].CONTENT
                setIntroText(intro)
                const about = text.filter(par=>par.SECTION === "about").map((row,n)=> (<div key={n}> 
                    <p className="aboutSubtitle">{row.TITLE}</p>
                    <p className="aboutText">{row.CONTENT}</p>
                </div>))
                setAboutText(about)
            })
    },[])
    return (
        <div id="SideBar">
            <img id="logo" src={logo} alt="KOAN" />
            <img id="about" src={about} alt="about" onClick={toggleAboutSection} />
            <div id="intro">
                {introText}
            </div>
            
            <View 
                setCompanySector={props.setCompanySector}
                companySector={props.companySector}
                sector={props.sector}
                companies={props.companies} 
                filterDataStructure={props.filterDataStructure} 
                setCompareSector={props.setCompareSector}
                update={props.update} 
                filters={props.filters} 
                defaultCompanySector={props.defaultCompanySector}
                availableFilters={props.availableFilters} 
                setSelectedCompany={props.setSelectedCompany}
                defaultCompany={props.defaultCompany}
                selectedCompany={props.selectedCompany}
                />
            <div id="aboutPage" data-active={false} >
                <div id="closeSection" onClick={toggleAboutSection} style={{"backgroundImage":`url(${closeIcon})`}}>
                    {/* <span>➔</span> &nbsp; hide  &nbsp;  */}
                </div>
                <p id="aboutTitleMain"> Materiality Monitor </p>
                <div id="aboutBody">
                    {aboutText}
                </div>
            </div>
            <div id="grayout"></div>
        </div>
    );
}

export default SideBar;